<template>
  <div class="search-sort-margin">
    <!-- <div class="search-sort">
      <div class="search-sort-item" :class="{active:sortSelect==0}" @click="handleSortClick(0)">
        <span>综合排序</span>
      </div>
      <div class="search-sort-item" v-for="(sort,index) in sortList" @click="handleSortClick(index+1,sort.params)"
        :class="{active:sortSelect==index+1}">
        <span>{{sort.name}}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
  import {
    sort
  } from '@/api/store';

  export default {
    data() {
      return {
        sortSelect: 0,
        sortList: [],
        sortParams: {}
      };
    },
    created() {
      this.$nextTick(() => {
        sort().then(data => {
          this.sortList = data;
        });
      });
    },
    methods: {
      handleSortClick(index, params) {
        this.sortSelect = index;
        this.sortParams = params || {};
      },
      getParams() {
        return {
          sort: "desc",
          ...this.sortParams
        };
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-sort {
    &-margin {
      margin: 20px 0px;
    }

    height: 28px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;

    &-item {
      cursor: pointer;
      display: inline-block;
      border: 1px solid #d5d9e4;

      &.active {
        border: 1px solid #1750ff;
        background: #1750ff;
        color: #fff;
      }

      >span {
        padding: 0px 15px;
      }
    }
  }
</style>