<template>
  <div class="w-1920">
    <header-user></header-user>
    <store-search ref="search"></store-search>
    <store-Info-chip></store-Info-chip>
    <store-image sid="1" :occupy="true"></store-image>
    <store-menu ref="menu"></store-menu>
    <div class="store">
      <!-- <div class="store-out-block w-1200">
        <div class="out-search">
          <span>所有商品</span>
          <i class="el-icon-arrow-right"></i>
          <div class="search">
            <input
              v-model="query"
              class="search-input"
              placeholder="想输什么就输什么"
            />
            <button class="search-btn" @click="handleSearchStore">
              <span>搜索</span>
            </button>
          </div>
        </div>
      </div> -->
      <div class="store-block w-1200">
        <div class="content">
          <!-- <store-search-classify></store-search-classify> -->
          <store-search-sort ref="sort"></store-search-sort>
          <store-wares ref="wares"></store-wares>
          <store-hotsale :num="12"></store-hotsale>
        </div>
      </div>
    </div>
    <!-- <baoyou></baoyou> -->
    <foot></foot>
    <cart></cart>
  </div>
</template>

<script>
import store from './mixins-components';
import index_search from './index-search';

export default {
  mixins: [store, index_search],
  computed: {
    query: {
      get: function() {
        return this.params.q || '';
      },
      set: function(newValue) {
        this.params.q = newValue;
      },
    },
  },
  data() {
    return {
      params: {
        q: '',
      },
    };
  },
  created() {
    this.params.q = this.$route.query.q;
  },
  methods: {
    getParams() {
      return {
        ...this.$refs.sort.getParams(),
        ...this.$refs.menu.getParams(),
        q: this.query,
      };
    },
    handleSearchStore() {
      this.$router.push({
        name: 'replace',
        params: {
          routeName: 'store_search',
          storeId: this.storeId,
        },
        query: {
          ...this.getParams(),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index-search.scss';
</style>
