<template>
  <div class="search-classify">
    <div class="search-classify-item">
      <div class="search-classify-title">
        <span>价格筛选</span>
      </div>
      <div class="search-classify-content">
        <ul class="search-classify-content-button">
          <li class="search-classify-content-button-item active">全部价格</li>
          <li class="search-classify-content-button-item">300-499</li>
          <li class="search-classify-content-button-item">500-499</li>
          <li class="search-classify-content-button-item">800-999</li>
        </ul>
      </div>
    </div>
    <div class="search-classify-item">
      <div class="search-classify-title">
        <span>商品筛选</span>
      </div>
      <div class="search-classify-content">
        <ul class="search-classify-content-button">
          <li class="search-classify-content-button-item active">全部商品</li>
          <li class="search-classify-content-button-item">标准气体</li>
          <li class="search-classify-content-button-item">食品标物</li>
          <li class="search-classify-content-button-item">环境标物</li>
          <li class="search-classify-content-button-item">职业卫生</li>
          <li class="search-classify-content-button-item">仪器检定</li>
          <li class="search-classify-content-button-item">其他标物</li>
          <li class="search-classify-content-button-item">化学试剂</li>
          <li class="search-classify-content-button-item">实验室配</li>
          <li class="search-classify-content-button-item">实验室配</li>
          <li class="search-classify-content-button-item">实验室配</li>
          <li class="search-classify-content-button-item">实验室配</li>
          <li class="search-classify-content-button-item">实验室配</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .search-classify {
    width: 100%;

    &-item {
      width: 100%;
      border-bottom: 1px solid #f4f4f4;
      display: inline-flex;
      flex-direction: row;
    }

    &-title {
      width: 90px;
      text-align: center;

      >span {
        display: inline-block;
        margin: 16px 0px;

        &:after {
          display: inline-block;
          content: ":";
          margin-left: 6px;
        }
      }
    }

    &-content {
      display: inline-block;
      flex: 100;

      &-button {
        list-style: none;
        padding: 0px;
        display: grid;
        justify-content: space-around;
        grid-template-columns: repeat(auto-fill, 100px);
        grid-gap: 10px 2px;

        &-item {
          display: inline-block;
          text-align: center;
          cursor: pointer;

          &:hover,
          &.active{
            color: #184fff;
          }
          position: relative;
          &:nth-of-type(11),
          &:first-of-type{
            &:before{
              content: "";
            }
          }
          &:before{
            position: absolute;
            left: -9px;
            color: #bababa;            
            content: "|";
          }
        }
      }
    }
  }
</style>