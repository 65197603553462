import header_user from '@/components/header';
import store_infochip from './store-Info-chip';
import store_search from './store-search';
import store_image from './store-image';
import store_menu from './store-menu';
import baoyou from '@/components/order/baoyou';
import foot from '@/components/foot';
import cart from '@/components/cart';

import store from './mixins-default';

export default {
  mixins:[store],
  components: {
    "header-user": header_user,
    "store-Info-chip": store_infochip,
    "store-search": store_search,
    "store-image": store_image,
    "store-menu": store_menu,
    "baoyou": baoyou,
    "foot": foot,
    "cart": cart,
  },
}