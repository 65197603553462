<template>
  <div class="hot-sale">
    <p class="hot-sale--title">店铺热销</p>
    <div class="hotsale">
      <div
        class="hotsale-item"
        v-for="item in list"
        @click="handleDetailClick(item)"
      >
        <div class="hotsale-item-img">
          <Uimage :src="item.spuImg" />
        </div>
        <div class="hotsale-item-title">
          <div class="hotsale-item-title-text">
            <span>{{ item.title }}</span>
          </div>
          <div class="hotsale-item-title-mony">
            <span>{{ item.priceRange }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // getskusalecount
  selHotSalesStores,
} from '@/api/store';

import mixins_store from './mixins-default';

export default {
  mixins: [mixins_store],
  props: {
    num: Number,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    selHotSalesStores(this.storeId, 1, 6).then((list) => {
      this.list = list.list;
    });
  },
  methods: {
    handleDetailClick(item) {
      // this.$router.push({
      //   name: 'detail',
      //   params: {
      //     storeId: item.storeId,
      //     spu: item.spuId,
      //   },
      //   query: {
      //     sku: item.skuId || '',
      //   },
      // });

       const { href } = this.$router.resolve({
        name: 'detail',
         params: {
          storeId: item.storeId,
          spu: item.spuId,
        },
          query: {
          sku: item.skuId || '',
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.hot-sale {
  border-top: 1px solid #e6e6e6;

  &--title {
    margin: 44px 0px;
    width: 100%;
    height: 15px;
    font-size: 15px;
    font-weight: 800;
  }

  .hotsale {
    width: 100%;
    min-height: 251.2px;
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 188px);
    grid-gap: 10px 0px;
    padding-bottom: 20px;

    &-item {
      border: #e2ecf6 2px solid;
      display: inline-block;
      width: 188px;
      height: 100%;

      &-img {
        height: 140px;
        //padding: 20px 20px;
        background: #e2ecf6;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        height: 78.5px;

        &-text {
          width: 92%;
          margin: 10px auto;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }

        &-mony {
          color: #e58426;
          width: 95%;
          margin: auto;
          font-weight: 700;

          &:before {
            content: '￥';
          }
        }
      }
    }
  }
}
</style>
