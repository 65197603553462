import store_wares from './store-wares';
import store_search_classify from './store-search-classify';
import hotsale from './store-hotsale';
import store_search_sort from './store-search-sort';

export default {
  components: {
    "store-wares": store_wares,
    "store-search-classify": store_search_classify,
    "store-search-sort":store_search_sort,
    "store-hotsale": hotsale,
  },
  created() {
    
    this.$nextTick(() => {
      this.$refs.menu.setSelectAll();
    });
  },
  
}